import React from 'react';
import classNames from 'classnames';

/* Import Stylesheet */
import styles from './styles.module.scss';

export type Props = React.ComponentProps<'div'>;

const cx = classNames.bind(styles);

/**
 * The 'PageLoader' partial is used to display a full page loader when
 * loading an entire page.
 */
export const PageLoader: React.FC<Props> = ({ className }: Props) => (
  <div className={cx(styles['page-loader'], className)}>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 740 741">
      <title>Loading...</title>
      <g>
        <polygon points="612.9 437.8 612.9 310.2 596.5 310.2 596.5 361.4 540 361.4 540 376.8 597.2 376.8 597.2 437.8 612.9 437.8" />
        <polygon points="528.7 437.5 528.7 309.9 513.2 309.9 513.2 438.5 528.7 437.5" />
        <polygon points="406.5 278.1 422.4 277.2 422.4 149.8 406.1 149.8 406.1 201.2 349.4 201.1 351.1 216.3 406.5 216.5 406.5 278.1" />
        <polygon points="480.4 215.9 520.7 215.9 519.4 201.1 480.4 201.1 480.4 215.9" />
        <polygon points="242.7 277.6 258.3 277.6 258.3 175.7 242.7 176.6 242.7 277.6" />
        <path d="M370,0C172.2,0,10.2,155.8,0,351c16.8-.5,18.7-23.9,18.7-23.9h0C40.1,152.4,189.5,16.5,370,16.5s343.3,148.3,353.5,334.5h16.5C729.8,155.7,567.8,0,370,0" />
        <path d="M738.1,392.2c-4.1.2-14.1,2.6-16.4,18.9h0c-20.2,176.1-170.2,313.4-351.7,313.4S27.9,577.3,16.7,392.2H.1c11.2,194.2,172.9,348.8,369.9,348.8s358.6-154.6,369.9-348.8h-1.8Z" />
        <path d="M315.9,311.4c-11.9-1.4-24.1-1.1-35.9-.8-5.1.1-10.4.3-15.5.3h-3.5v127.7l16.4-1.4v-112.1h23.7c2.6,0,5.3,0,7.9.4,12.5,1.8,19.8,9.8,19.5,21.5-.3,12.2-8.2,20-21.2,20.6-4.2.2-11.1.3-14.1.3h-.7c-6.2.2-5.9,1.9-5.9,1.9v13.3s3,0,6.1,0c6.6.3,13.3.2,19.9-.3,18.6-1.8,31.8-15.8,32.9-34.8s-11.3-34.6-29.4-36.7" />
        <path d="M230.2,415.1c-12.5-29.7-25.4-60.3-38.1-90.5h0l-9.9-23.4s-12.3,5.4-8.1,21.4c.2,1.1.6,2.2,1,3.2.2.4.4.9.6,1.3h0c0,.1,0,.3,0,.3,5.4,13,10.9,26.1,16.4,39.1,8.6,20.5,17.5,41.7,26.1,62.7,2.5,6.1,6.1,9.1,11.1,9.1,2.1,0,4.2-.5,6.2-1.2l3.5-1.3-8.8-20.7Z" />
        <path d="M196.6,404.8c-2.8-9.8-3.9-11.3-12.9-11.4h-25.9l13.2-31.6c.1-.2.2-.4.3-.7,5.5-12.7-5.6-28.4-5.6-28.4l-43.4,103.3,3.7,1.2c1.9.6,3.8,1,5.8,1,1.5,0,3.1-.3,4.5-.9h0c.6-.3,1.2-.7,1.8-1.1l.2-.2c.3-.2.7-.5.9-.8,1.1-1.1,2-2.5,2.7-3.9l8.4-20.1c.4-.4.8-.8,1.3-1.1,2.9-1.9,8.3-1.5,13.4-1.1,2.4.2,4.9.4,7.3.3,6.6,0,5.2,0,11.9,0h13.6c0,0-1.3-4.5-1.3-4.5Z" />
        <path d="M198.6,149.8s0,14.7,4.2,14.7h100.5v-14.7h-104.6Z" />
        <path d="M322.6,153.2v124.4h15.5v-128.6s-15.5.9-15.5,4.2" />
        <path d="M533.5,262.1h-64.4v-97.3h59.9c5.3,0,5.3-15.5,5.3-15.5h-81.9v128.1h84.2c0-15.3-3.2-15.3-3.2-15.3" />
        <path d="M354.9,431.9c-2.9-4.1-5.8-8.2-8.6-12.2-7.2-10.1-9.5-13.5-16.1-23.4-6.7-10-22.5-2.9-22.5-2.9l2.4,3.4c2.9,4,1.3,1.9,4.2,5.9,7,9.8,13.6,19,20,28.6,3.4,5.1,7.4,7.5,12.4,7.5,2.2,0,4.4-.4,6.4-1.1l4.9-1.5-3-4.3Z" />
        <path d="M487.8,334.2c-8.7-13.1-22.7-22.1-39.3-25.2-14.1-2.7-28.6-.7-41.5,5.5-19.1,10-14.3,24.9-13.1,28.4l.3.7,3.9-3.8c12.4-11.8,26.4-17,41.1-16.1,13.8.8,26.5,7.6,36.8,19.7l2.2,2.7,11.4-9.3-1.8-2.7Z" />
        <path d="M478.1,401.7l-2.2,2.3c-18,18.9-37.3,24.4-57.6,16.4-15.4-6.1-25.1-18.1-28.8-35.1-1.2-6.7-2.1-13.4-2.5-20.2,0-.5,0-.9,0-1.4-.6-14.4-5.7-22-5.7-22-12.1,17.7-14.1,37-5.7,57.4,9.8,24,28.8,38,55,40.4,2.4.2,4.7.3,7,.3,20.5,0,37.5-8.7,50.6-26l2.1-2.8-12.1-9.4Z" />
        <path d="M501.8,529.4h0s0,0,0-.1c0-.4,0-.8,0-1.2h0c-1.9-16.9-10.4-32.3-23.6-42.9-23.4-18.6-58.4-19.2-81.4-1.4-7.4,5.7-7.5,8.1-4.8,17.5l1.6,5.6,4.3-4.1c13.2-12.7,27.5-18,43.6-16.2,25.9,2.8,43.1,22,43.8,48.7,0,0-.2,28.8,6,35.7,0,0,12.8-15.9,10.7-41.6" />
        <path d="M477,568.4s-13.4,15.7-33,18.2h0c-8.8,1.4-18,0-27.4-4-18.8-8.1-28.5-23.9-28.9-46.8h0c-.8-21.7-9.7-28.8-9.7-28.8-14.8,25.5-7.4,61.9,16.5,81.2,12.1,9.6,27.1,14.8,42.5,14.8,13,0,25.8-3.8,35.9-11.2,11.6-9,4.1-23.4,4.1-23.4" />
        <path d="M350.7,497.2c-8.7-13.1-22.7-22.1-39.3-25.2-14-2.7-28.6-.7-41.4,5.5-19.1,10-14.3,24.9-13.1,28.4l.3.7,3.9-3.8c12.4-11.8,26.4-17,41.1-16.2,13.9.8,26.6,7.6,36.8,19.7l2.2,2.7,11.4-9.3-1.8-2.7Z" />
        <path d="M341,564.7l-2.2,2.3c-18,18.9-37.3,24.4-57.6,16.4-15.4-6.1-25.1-18.1-28.8-35.1-1.2-6.7-2-13.4-2.5-20.2,0-.5,0-.9,0-1.4-.6-14.4-5.7-22-5.7-22-12.1,17.7-14.1,37-5.7,57.4,9.8,24,28.8,38,55,40.4,2.4.2,4.7.3,7,.3,20.5,0,37.5-8.7,50.6-26l2.1-2.8-12.2-9.4Z" />
      </g>
    </svg>
    <p>Loading...</p>
  </div>
);

export default PageLoader;
